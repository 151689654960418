<template>
    <li class="list-group-item d-flex justify-content-between align-items-start border-0 mb-3 p-3 rounded">
        <div class="ms-2 pointer" @click="setDisplay(true)">
            <div class="fw-bold mb-1">
                Pedido #{{ order.id }} - {{ dateFormatted(order.date_created) }} <i class="bi bi-info-circle"></i>
            </div>
            <div class="d-flex align-items-center justify-content-between w-100">
                <p class="mb-0 text-muted">Importe total {{ currencyFormatted(parseFloat(order.total)) }} </p>
            </div>

        </div>

        <span :class="`badge bg-${order.status} rounded-pill text-capitalize`">{{ getOrderStatus(order.status) }}</span>
        <order-info-modal @on-repeat="handleRepeat" :order="order" :display="display" @on-close="setDisplay(false)" />
    </li>
</template>

<script setup>
import {ref} from 'vue'
import {currencyFormatted} from '@/helpers/currency'
import {dateFormatted} from '@/helpers/moment'
import { useCheckoutStore } from '@/stores/checkout';
import { swalToast } from '@/helpers/swal';
import { getOrderStatus } from '@/utils/order';
import OrderInfoModal from './OrderInfoModal.vue';

const store = useCheckoutStore()
const display = ref(false)
const props = defineProps({
    order: {
        type: Object,
        required: true
    }
})

const handleRepeat = (items) => {
    store.cloneCart(items)

    swalToast("Todos los productos han sido añadidos al carrito", "success")
    display.value = false
}

function setDisplay(value) {
    display.value = value
}
</script>