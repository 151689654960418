export const getOrderStatus = (status) => {
    switch (status) {
        case 'pending':
            return 'Pendiente'
        case 'on-hold':
            return 'En espera'
        case 'processing':
            return 'En proceso'
        case 'completed':
            return 'Completado'
        case 'cancelled':
            return 'Cancelado'
        case 'refunded':
            return 'Reembolsado'
        case 'failed':
            return 'Fallido'
        default:
            return 'Pendiente'
    }
}	